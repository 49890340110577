// 引入 axios
import axios from "axios";



let base = 'https://xzcx.hnmykj.vip/service-api/salaryHelper/toukbike';
// let base = 'http://127.0.0.1:7777/salaryHelper/toukbike';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
